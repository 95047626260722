import React, { useState } from "react";
import { Tabs, Tab, Container, Row, Col, Nav } from "react-bootstrap";
import LearnPageMain from "./learnPageMain";
import { motion } from "framer-motion";
import { useSpring, animated } from 'react-spring';
import TopMenu from "../../../menu/topMenu";
import 'bootstrap/dist/css/bootstrap.min.css';
import './learnPage.css';


function LearnPage() {
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState("basic");
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }
  });

  // Motion variants
  const tabVariants = {
    initial: { scale: 0 },
    animate: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    },
    exit: { scale: 0 }
  }


  return (

    <animated.div className="LearnPage" style={props}>
    <div className="LearnPage">
      <header className="LearnPage-header">
        <TopMenu />
      </header>
      
      <div className="LearnPage-body">
        <Container fluid className="content-container">
          <Tabs className="rounded-tabs" defaultActiveKey="basic">
            <Tab eventKey="basic" title="Basic">
              <LearnPageMain/>
            </Tab>
            <Tab eventKey="terminology" title="Terminology">
              <LearnPageMain/>
            </Tab>
            <Tab eventKey="research" title="Research">
              <LearnPageMain/>
            </Tab>
            <Tab eventKey="extensions" title="Extensions">
              <LearnPageMain/>
            </Tab>
          </Tabs>
        </Container>
      </div>
      
      <div className="LearnPage-footer">
        {/* Footer content */}
      </div>
    </div>
    </animated.div>
  );
}

export default LearnPage;
