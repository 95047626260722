import React from 'react';

const NotFound = () => {
    return (
        <div>
            404 Error
        </div>
    );
};
  
export default NotFound;