import React, { useState } from 'react';
import axios from 'axios';
import GoogleLoginButton from '../components/Button/GoogleLoginButton';
import Modal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import './LoginPage.css'

Modal.setAppElement('#root');

const LoginPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://3nxqjhoy2j.execute-api.ap-northeast-2.amazonaws.com/RegisterProd/register', { email, password });
      console.log(response.data);
    } catch (error) {
      console.error('Error logging in user:', error);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const { tokenId } = response;
      const result = await axios.post('https://3nxqjhoy2j.execute-api.ap-northeast-2.amazonaws.com/RegisterProd/register', { tokenId });
      console.log(result.data);
    } catch (error) {
      console.error('Error logging in with Google:', error);
    }
  };

  const handleGoogleFailure = (response) => {
    console.error('Error logging in with Google:', response);
  };

  return (
    <div className='LoginPageForm-css'>
      <form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
        </Form.Group>

        <Button variant="primary" type="submit">
          Login
        </Button>
      </form>
      <GoogleLoginButton
        buttonText="Login with Google"
        onSuccess={handleGoogleSuccess}
        onFailure={handleGoogleFailure}
        cookiePolicy={'single_host_origin'}
      />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <h2>Login Error</h2>
        <p>There was an error while logging in. Please try again.</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default LoginPage;
