import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AccountSettingPage from "../page/accountSettingPage";
import InfoPage from "../page/infoPage";
import NotFound from '../page/notFoundPage';
import MainPage from '../page/mainPage/mainPage';
import WritingPage from '../page/WritingPage';
import LearnPage from '../Theme/learner/page/learnPage';
import LearnWritingPage from '../Theme/learner/page/learnWritingPage';
import BotGuidePage from "../page/BotGuidePage";
import WildcardHelperPage from "../page/WildcardHelperPage";
import PrivacyPolicy from '../components/Privacy Policy/PrivacyPolicy';
import LoginPage from '../page/loginPage';
import RegistrationPage from '../page/RegistrationPage';
import CommentBoxPage from "../page/CommentBoxPage";
import WorkspacePage from "../menu/Other/WorkspacePage";


const Router = ({isLoggedIn, userObj}) => {

    return (
        <div className="App">

            <BrowserRouter>
                <Routes>
                <Route path="/" element={isLoggedIn ? <Navigate to="/MainPage"/> : <Navigate to="/MainPage" /> }/>
                <Route path="/MainPage" element={<MainPage />}/>
                <Route path="/AccountSettingPage" element={<AccountSettingPage />} />
                <Route path="/RegistrationPage" element={<RegistrationPage />} />
                <Route path="/LoginPage" element={<LoginPage />} />
                <Route path="/InfoPage" element={<InfoPage />} />
                <Route path="/WritingPage" element={<WritingPage />} />
                <Route path="/LearnPage" element={<LearnPage />} />
                <Route path="/BotGuidePage" element={<BotGuidePage />} />
                <Route path="/LearnWritingPage" element={<LearnWritingPage />} />
                <Route path="/WildcardHelperPage" element={<WildcardHelperPage />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/CommentBoxPage" element={<CommentBoxPage />}/>
                <Route path="/WorkspacePage" element={<WorkspacePage />}/>

                
                {/* 외부 링크로 리디렉션 */}
                <Route path="/blog" element={<Navigate to="https://your-blog-url" replace />} />
                <Route path="/discord" element={<Navigate to="https://your-discord-invite-url" replace />} />

                {/* 404 Not Found 페이지 */}
                <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Router;
