import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const RegistrationSuccessForm = () => {
    return (
        <Container className="RegistrationSuccessForm">
            <Row className="justify-content-md-center">
                <Col xs={12} md={8} lg={6}>
                    <h1>Registration Successful!</h1>
                    <p>Thank you for registering. Your account is now active.</p>
                    <p>Get started by logging in or return to the homepage.</p>
                    <div>
                        <Button variant="primary" size="lg" block href='/Main'>
                            OK
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default RegistrationSuccessForm;
