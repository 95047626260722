import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, GithubAuthProvider, signInWithPopup } from "firebase/auth";
import Modal from 'react-modal';
import { Form, Button } from 'react-bootstrap';
import { updateProfile } from "firebase/auth";
import RegistrationSuccessForm from './RegistrationSuccessForm';
import './RegistrationForm.css'

const RegistrationForm = () => {
  const auth = getAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [passwordError, setPasswordError] = useState(''); // Add this state for password error

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlePasswordConfirmChange = (event) => {
    setPasswordConfirm(event.target.value);
  };

  const handledisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== passwordConfirm) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: displayName });
      setIsRegistrationSuccessful(true);
    } catch (error) {
      console.error('Error registering user:', error);
      setModalIsOpen(true);
    }
  };

  const handleGoogleSuccess = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // If user's displayName is not set, set it as the part of email before the '@'
      if (!user.displayName) {
        const displayName = user.email.split('@')[0];
        await updateProfile(user, { displayName: displayName });
      }
    } catch (error) {
      console.error('Error logging in with Google:', error);
      setModalIsOpen(true);
    }
  };
  const handleGithubSuccess = async () => {
    const provider = new GithubAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // If user's displayName is not set, set it as the part of email before the '@'
      if (!user.displayName) {
        const displayName = user.email.split('@')[0];
        await updateProfile(user, { displayName: displayName });
      }
    } catch (error) {
      console.error('Error logging in with Github:', error);
      setModalIsOpen(true);
    }
  };

  if (isRegistrationSuccessful) {
    return <RegistrationSuccessForm />; // Return the RegistrationSuccessForm when registration is successful
  }

  return (
<div className="registration-container">
  <form className="registration-form" onSubmit={handleSubmit}>
    <Form.Group controlId="formBasicName" className="registration-form-group">
      <Form.Label>Nickname</Form.Label>
      <Form.Control className="registration-form-control" type="displayName" value={displayName} onChange={handledisplayNameChange} />
    </Form.Group>

    <Form.Group controlId="formBasicEmail" className="registration-form-group">
      <Form.Label>Email address</Form.Label>
      <Form.Control className="registration-form-control" type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
    </Form.Group>

    <Form.Group controlId="formBasicPassword" className="registration-form-group">
      <Form.Label>Password</Form.Label>
      <Form.Control className="registration-form-control" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
    </Form.Group>

    <Form.Group controlId="formBasicPasswordConfirm" className="registration-form-group">
      <Form.Label>Confirm Password</Form.Label>
      <Form.Control className="registration-form-control" type="password" placeholder="Confirm Password" value={passwordConfirm} onChange={handlePasswordConfirmChange} />
      {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
    </Form.Group>

    <Button variant="primary" type="submit" className="registration-submit-btn">
      Create Account
    </Button>

    <div className="registration-social-buttons">
      <button onClick={handleGoogleSuccess} name='google' className="registration-social-btn google-btn">
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="google logo" className="social-logo" />
        Login with Google
      </button>
      <button onClick={handleGithubSuccess} name='github' className="registration-social-btn github-btn">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/GitHub_Invertocat_Logo.svg/640px-GitHub_Invertocat_Logo.svg.png" alt="github logo" className="social-logo" />
        Login with Github
      </button>
    </div>
  </form>
  
  <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="registration-modal">
    <h2>Login Error</h2>
    <p>There was an error while logging in. Please try again.</p>
    <button onClick={closeModal} className="registration-modal-close-btn">Close</button>
  </Modal>
</div>

  );
};

export default RegistrationForm;
