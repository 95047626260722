import React from 'react';
import TopMenu from '../../menu/topMenu';
import ContentSectionOne from './ContentSection/ContentSectionOne';
import ContentSectionTwo from './ContentSection/ContentSectionTwo';
import ContentSectionThree from './ContentSection/ContentSectionThree';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './mainPage.scss';


const MainPage = () => {
    return (
      <div className="scroll-container">
        <TopMenu/>
        <div className="section" id="section1">
        <ContentSectionThree/>
        </div>
        <div className="section" id="section2">
        <ContentSectionTwo/>
        </div>
        <div className="section" id="section3">
        <ContentSectionOne/>
        </div>
      </div>
    );
  }

export default MainPage;
