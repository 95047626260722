import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './ContentSectionThree.scss';
import Snow from './snow';

const images = [
    {
        url: 'https://cdn.mos.cms.futurecdn.net/dP3N4qnEZ4tCTCLq59iysd.jpg',
        title: "Lossless Youths",
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim',
        website: 'https://united-prompt.tistory.com/12'
    },
    {
        url: 'https://i.redd.it/tc0aqpv92pn21.jpg',
        title: "Estrange Bond",
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
        website: 'https://united-prompt.tistory.com/12'
    },
    {
        url: 'https://wharferj.files.wordpress.com/2015/11/bio_north.jpg',
        title: "The Gate Keeper",
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
        website: 'https://united-prompt.tistory.com/12'
    },
    {
        url: 'https://images7.alphacoders.com/878/878663.jpg',
        title: "Last Trace Of Us",
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
        website: 'https://united-prompt.tistory.com/12'
    },
    {
        url: 'https://theawesomer.com/photos/2017/07/simon_stalenhag_the_electric_state_6.jpg',
        title: "Urban Decay",
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
        website: 'https://united-prompt.tistory.com/12'
    },
];

const ContentSectionThree = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const openWebsite = (website) => {
        window.open(website, '_blank', 'noopener,noreferrer');
    };
    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div className="content-section-three">

            <div className="image-slider">
                {images.map((image, index) => (

                    <div
                        key={index}
                        className={`slide ${index === currentIndex ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${image.url})` }}
                    >
                        <Snow />
                        {index === currentIndex && (
                            <div className="slide-content">

                                <h2 className="slide-title">{image.title}</h2>
                                <p className="slide-description">{image.description}</p>
                                <button onClick={() => openWebsite(image.website)} className="slide-read-more">
                                    Read More
                                </button>
                            </div>
                        )}
                    </div>
                ))}


            </div>

            <div className="slider-controls">
                <button className="control-button" onClick={goToPrev}>
                    <FaArrowLeft />
                </button>
                <button className="control-button" onClick={goToNext}>
                    <FaArrowRight />
                </button>
            </div>
        </div>
    );
};

export default ContentSectionThree;
