import React, { useContext, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useTopMenu } from './TopMenuContext';
import Chatbot from '../components/chatbot/Chatbot';
import { Link } from 'react-router-dom';
import './topMenu.scss';


const TopMenu = () => {

  const [showChatbot, setShowChatbot] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({
    software: false,
    community: false,
    contact: false
  });
  
  const { unlockedFeatures } = useTopMenu();

  const handleChatbotToggle = () => {
    setShowChatbot(!showChatbot);
  };
  const handleMouseEnter = (menu) => {
    setDropdownVisible({ ...dropdownVisible, [menu]: true });
  };
  const handleMouseLeave = (menu) => {
    setDropdownVisible({ ...dropdownVisible, [menu]: false });
  };

  return (
    <Navbar expand="lg" variant="dark" className="custom-navbar">
      <Link className="navbar-brand" to="/MainPage">United Prompt</Link>
      <Nav className="mr-auto">

        <div
          className="nav-item dropdown"
          onMouseEnter={() => handleMouseEnter('software')}
          onMouseLeave={() => handleMouseLeave('software')}
        >
          <span className="nav-link dropdown-toggle">Software</span>
          {dropdownVisible.software && (
            <div className="dropdown-menu">
              <Link to="/ListPiz" className="dropdown-item">ListPiz</Link>
              <Link to="/ToonPlz" className="dropdown-item">ToonPlz</Link>
              <Link to="/TalkPlz" className="dropdown-item">TalkPlz</Link>
            </div>
          )}
        </div>


        <div
          className="nav-item dropdown"
          onMouseEnter={() => handleMouseEnter('community')}
          onMouseLeave={() => handleMouseLeave('community')}
        >
          <span className="nav-link dropdown-toggle">Community</span>
          {dropdownVisible.community && (
            <div className="dropdown-menu">
              <Link to="/discord" className="dropdown-item">Discord (work in progress)</Link>
              <a href="https://united-prompt.tistory.com/" target="_blank" rel="noopener noreferrer" className="dropdown-item">Blog</a>
            </div>
          )}
        </div>

        <div
          className="nav-item dropdown"
          onMouseEnter={() => handleMouseEnter('contact')}
          onMouseLeave={() => handleMouseLeave('contact')}
        >
          <span className="nav-link dropdown-toggle">Other</span>
          {dropdownVisible.contact && (
            <div className="dropdown-menu">
              <Link to="/WorkspacePage" className="dropdown-item">WORKSPACE</Link>
            </div>
          )}
        </div>

        <div
          className="nav-item dropdown"
          onMouseEnter={() => handleMouseEnter('contact')}
          onMouseLeave={() => handleMouseLeave('contact')}
        >
        {unlockedFeatures.length > 0 && (
        <div className="dropdown">
          <span className="nav-link dropdown-toggle">unlocked</span>
          <div className="dropdown-content">
            {unlockedFeatures.map((feature, index) => (
                          <div className="dropdown-menu">
                          <Link onClick={handleChatbotToggle} className="dropdown-item">chatbot</Link>
                        </div>
            ))}

          </div>
        </div>
      )}
        </div>
        <Chatbot showChatbot={showChatbot} setShowChatbot={setShowChatbot} />
      </Nav>
    </Navbar>
  );
}

export default TopMenu;
