import { collection, addDoc, getDocs, onSnapshot } from "firebase/firestore";
import { firestoreDB } from "../../FbaseInstance";
import { useEffect, useState } from "react";
import useStore from "../../Usestore";
import { database } from "../../FbaseInstance";
import { ref, onValue, set } from "firebase/database";
import CommentModify from "./CommentModify";
import "./CommentBox.scss";

const CommentBox = () => {
  const userObj = useStore(state => state.userObj);
  const tweet = useStore(state => state.tweet);
  const setTweet = useStore(state => state.setTweet);
  const tweets = useStore(state => state.tweets);
  const setTweets = useStore(state => state.setTweets);



  //실시간 데이터베이스
  function writeUserData(userId, name, email, imageUrl) {
    set(ref(database, 'tweets/' + userId), {
      username: name,
      email: email,
      profile_picture: imageUrl
    });
  }
  //추천수
  //   const starCountRef = ref(database, 'posts/' + postId + '/starCount');
  // onValue(starCountRef, (snapshot) => {
  //   const data = snapshot.val();
  //   updateStarCount(postElement, data);
  // });


  //작동 , foreach를 사용하면 리렌더링이 일어나 더 시간이 걸림.
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestoreDB, "tweet"), (snapshot) => {
      const tweetArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(tweetArray)
      setTweets(tweetArray)
    });
    return () => unsubscribe(); 
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(firestoreDB, "tweet"), {
        text: tweet,
        createdAt: Date.now(),
        creatorId: userObj.uid,
      });
       console.log("Document written with ID: ", docRef.id);
       console.log("Document written with ID: ", userObj);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const onChange = (e) => {
    setTweet(e.target.value);
  };

  // const onChange=(e)=>{
  //     const{target:{value},
  // }=e;
  // setTweet(value)
  // }

  return (
    <div className="CommentBox">
      <form className="comment-box__form" onSubmit={onSubmit}>
        <input value={tweet} onChange={onChange} type="text" placeholder="write anything" maxLength={120} />
        <input value="tweet" type="submit" />

      </form>
      <div className="comment-box__comments">
      {userObj && tweets.map((tweet) =>
  <CommentModify key={tweet.id} tweetObj={tweet} isOwner={tweet.creatorId === userObj.uid} />
)}

      </div>
    </div>
  )
}




export default CommentBox;
