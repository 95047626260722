import React, { useState, useRef } from 'react';
import './WildcardHelper.css';

const WildcardHelper = () => {

    const [displayText, setDisplayText] = useState("");
    const [categorizedText, setCategorizedText] = useState("");
    const [messageInput, setMessageInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    

    const handleFilter = (filterType) => {
        if (filterType === 'all') {
            setCategorizedText(displayText);
            return;
        }
        let displayLines = displayText.split('\n');
        let filteredLines = displayLines.filter(line => line.toLowerCase().startsWith(filterType));
        setCategorizedText(filteredLines.join('\n'));
        console.log(displayText)
        console.log(categorizedText)
    };


    const fetchResponse = async (input) => {
        try {

            const response = await fetch("https://74vamgekba54nbpr66iao4xo5u0nfzse.lambda-url.ap-northeast-2.on.aws/WildcardFunc", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userMessages: [{ content: input }] }),
            });

            const result = await response.json();

            return result.assistant;
        } catch (error) {
            console.error("Error:", error);
            return "Error fetching response from server.";
        }
    };

    const insertMessage = async () => {
        if (!messageInput.trim()) {
            return false;
        }

        setIsLoading(true);


        const response = await fetchResponse(messageInput);
        setDisplayText(response);  // set the response from the chatbot as the display text
        setMessageInput('');
        setIsLoading(false);
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            insertMessage();
            e.preventDefault();
        }
    };


    return (
        <div className="container">
            <h2>Text Categorization Page</h2>
            <div className="textarea-container">
                <textarea
                    type="text"
                    className="inputTextarea"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Write your theme here"
                />
                <button className="categorizeButton" onClick={insertMessage}>{isLoading ? 'Loading...' : 'RUN'}</button>

                <textarea
                    className="displayTextarea"
                    value={displayText}
                    onChange={(e) => setDisplayText(e.target.value)}
                />
                <div className="buttonContainer">
                    <button onClick={() => handleFilter('Situation')}>Situation</button>
                    <button onClick={() => handleFilter('Background and Details')}>Background and Details</button>
                    <button onClick={() => handleFilter('Hair')}>Hair</button>
                    <button onClick={() => handleFilter('Clothes')}>Clothes</button>
                    <button onClick={() => handleFilter('Mood')}>Mood</button>
                    <button onClick={() => handleFilter('Effect')}>Effect</button>
                    <button onClick={() => handleFilter('Action')}>Action</button>
                </div>
                <textarea
                    className="categorizedTextarea"
                    value={categorizedText}
                />
            </div>
        </div>
    );
}

export default WildcardHelper;
