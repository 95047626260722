import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import './App.scss';
import Router from './routes/Router';
import { TopMenuProvider } from './menu/TopMenuContext';


function App() {

  return (
    <div className="App">
      <TopMenuProvider>
      <Router />
      </TopMenuProvider>
    </div>
  );
}
export default App;
