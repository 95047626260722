import React from "react";


const CommentModify = ({tweetObj, isOwner}) =>{
    // onDeleteClick =()=>{
    //     const ok = window.confirm("Are You Sure you want to delete this tweet?");
    //     if(ok){
    //         dbservice.doc('')
    //     }
    // }
    return(
    <div>
        <h4>{tweetObj.text}</h4>
        {isOwner &&(
        <>
        <button>DELETE</button>
        <button>Modify</button>
        </>
)}
    </div>
)}
export default CommentModify;

