import React, { useState, useRef } from 'react';
import TopMenu from '../menu/topMenu';
import WildcardHelper from '../components/chatbot/WildcardHelper';
const WildcardHelperPage = () => {


    return (
        <div>
            <header><TopMenu /></header>
            <section>
                <WildcardHelper />
            </section>
        </div>
    );
}

export default WildcardHelperPage;
