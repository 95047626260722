import React from 'react';
import TopMenu from '../menu/topMenu';
import CommentBox from '../components/CommentBox/CommentBox';

const CommentBoxPage = () => {
  return (
    <div>
      <TopMenu/>
      <CommentBox/>
    </div>
  );
};

export default CommentBoxPage;