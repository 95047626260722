// context/TopMenuContext.js
import React, { createContext, useState, useContext } from 'react';

const TopMenuContext = createContext();

export const useTopMenu = () => useContext(TopMenuContext);

export const TopMenuProvider = ({ children }) => {
  const [unlockedFeatures, setUnlockedFeatures] = useState([]);

  const unlockFeature = (featureName) => {
    if (!unlockedFeatures.includes(featureName)) {
      setUnlockedFeatures([...unlockedFeatures, featureName]);
    }
  };

  const contextValue = {
    unlockedFeatures,
    unlockFeature
  };

  return (
    <TopMenuContext.Provider value={contextValue}>
      {children}
    </TopMenuContext.Provider>
  );
};
