import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './ContentSectionTwo.scss';

const br = "/br"
const softwareList = [
  {
    id: 1,
    name: 'Listplz',
    description: 'An advanced text editor for developers.',
    image: 'https://via.placeholder.com/150?text=Alpha+Software',
  },
  {
    id: 2,
    name: 'Chatplz',
    description: 'A powerful tool for data analysis and visualization.',
    image: 'https://via.placeholder.com/150?text=Beta+Analytics',
  },
  {
    id: 3,
    name: 'Toonplz',
    description: 'A robust security suite to protect your digital life.',
    image: 'https://via.placeholder.com/150?text=Gamma+Security',
  },
  {
    id: 4,
    name: 'United Prompt',
    description: `This is an old icon, but I still use it because it's cute.More를 클릭하면 현재 useSword가 개발 중인 것들에 대해 알아볼 수 있습니다.`,
    image: 'https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FU2UVG%2FbtsDI9E9Akb%2Ff1vpTRvGOd6mbR3dcNggP1%2Fimg.png',
    url: "",
  },
  {
    id: 5,
    name: 'Talkplz',
    description: 'A robust security suite to protect your digital life.',
    image: 'https://via.placeholder.com/150?text=Gamma+Security',
  },
  {
    id: 6,
    name: 'Talkplz',
    description: 'A robust security suite to protect your digital life.',
    image: 'https://via.placeholder.com/150?text=Gamma+Security',
  },
];


const ContentSectionTwo = () => {
  const [activeSoftware, setActiveSoftware] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);


  const itemsPerPage = 4;
  const maxPageIndex = Math.ceil(softwareList.length / itemsPerPage) - 1;



  const displayedSoftware = softwareList.slice(
    pageIndex * itemsPerPage,
    pageIndex * itemsPerPage + itemsPerPage
  );

  const handleCardClick = (software) => {
    setActiveSoftware(software);
    setAnimationKey(prevKey => prevKey + 1);
  };

  const handleNextClick = () => {
    setPageIndex(prevIndex => Math.min(prevIndex + 1, maxPageIndex));
  };

  const handlePrevClick = () => {
    setPageIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };


  return (
    <div className="content-section-two">
      <div className="carousel-container">
        <div className="card-container">
          {displayedSoftware.map((software) => (
            <div
              key={software.id}
              className={`software-card ${activeSoftware === software ? 'active' : ''}`}
              onClick={() => handleCardClick(software)}
            >
              <img src={software.image} alt={software.name} />
            </div>
          ))}
        </div>
      </div>
      <div className="button-container">
        <button onClick={handlePrevClick} disabled={pageIndex === 0}>
          <FaArrowLeft />
        </button>
        <button onClick={handleNextClick} disabled={pageIndex === maxPageIndex}>
          <FaArrowRight />
        </button>
      </div>
      <div className="software-details">
        {activeSoftware ? (
          <>
            <div key={animationKey} className="software-description-container software-description-animate">
              <p className="software-description">{activeSoftware.description}</p>
            </div>
            <button className="find-dealer-button" onClick={activeSoftware.url}>VIEW More INFO</button>
          </>
        ) : (
          <p>Select a Image to see details.</p>
        )}
      </div>
      {activeSoftware && (
        <h1 className="software-title">{activeSoftware.name}</h1>
      )}
    </div>
  );
};


export default ContentSectionTwo;
