import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';

const Chatbot = ({showChatbot,setShowChatbot}) => {
  const [userMessages, setUserMessages] = useState([]);
  const [assistantMessages, setAssistantMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const messagesEndRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);



  const setDate = () => {
    const d = new Date();
    const h = d.getHours();
    const m = d.getMinutes().toString().padStart(2, '0');
    const s = d.getSeconds().toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
  };

  const fetchResponse = async (input) => {
    try {
      const response = await fetch("https://gkzp6e6pvhy6ubggp3rn3nrsxe0xjusl.lambda-url.ap-northeast-2.on.aws/ChatbotFunc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userMessages: [{ content: input }] }),
      });
      if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
      const result = await response.json();
      return result.assistant;
    } catch (error) {
      console.error("Error:", error);
      return "Error fetching response from server.";
    }
  };

  const insertMessage = async () => {
    if (!messageInput.trim()) {
      return false;
    }

    const userMessage = {
      content: messageInput,
      type: 'personal',
      timestamp: setDate(),
    };
    setUserMessages([...userMessages, userMessage]);
    setMessageInput('');

    const response = await fetchResponse(messageInput);
    setTimeout(() => {
      setAssistantMessages([
        ...assistantMessages,
        { content: response, type: 'fake', timestamp: setDate() },
      ]);
    }, 1000);
    console.log(userMessage)
  };

  const handleKeyPress = (e) => {
    if (e.which === 13) {
      insertMessage();
      e.preventDefault();
    }
  };

  const handleClose = () => {
    setShowChatbot(false);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const allMessages = [...userMessages, ...assistantMessages].sort((a, b) => a.timestamp.localeCompare(b.timestamp));

  return (
    showChatbot && (
      <div className={`chat${isMinimized ? " chat-minimized" : ""}`}>
        <div className="chat-title">
          <h2>Prompt Helper</h2>
          <button className="chat-close" onClick={handleClose}>Close</button>
          <button className="chat-minimize" onClick={handleMinimize}>Minimize</button>
        </div>

        <div className="messages">
          <div className="messages-content">
            {allMessages.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                {message.type === 'fake' && (
                  <figure className="avatar">
                    <img src="https://cdn-icons-png.flaticon.com/512/1904/1904608.png" alt="avatar"/>
                  </figure>
                )}
                {message.content}
                <div className="timestamp">{message.timestamp}</div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div className="message-box">
          <textarea
            id="chatbot-textarea"
            type="text"
            className="message-input"
            placeholder="Type message..."
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
    )
  );
};

export default Chatbot;