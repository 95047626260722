import React, { useState, useRef } from 'react';
import { FaDiscord, FaBloggerB, FaEnvelope } from 'react-icons/fa';
import { Card,Layout } from 'antd';
import './ContentSectionOne.scss';



const ContentSectionOne = () => {
    const { Footer } = Layout;
    
    const [selectedVideo, setSelectedVideo] = useState(null);
    const videos = [
        {
            id:1,
            path:'https://v.ftcdn.net/04/69/91/73/240_F_469917363_CAPmea9AR1GVJeYmBnbs5W1zt25oQ3WP_ST.mp4'
        },
        {
            id:2,
            path:'https://v.ftcdn.net/02/29/08/95/700_F_229089547_KmxPRLqLI83lX4AG6pqSqUBYcG3qm8ew_ST.mp4'
        },
        {
            id:3,
            path:'https://v.ftcdn.net/04/56/14/39/240_F_456143953_2pjI3tAgMI8zrCS3cD9OwvThVHVNenTP_ST.mp4'
        }
    ];

    const canvasRef = useRef(null);


    return (
        <div className="content-section-one">
            <video autoPlay loop muted className="video-background" src={selectedVideo || videos[0].path}>
                Your browser does not support the video tag.
            </video>
            <div className="video-overlay">
                <h1>Experience AI</h1>
                <p>I will provide products and services that are comfortable for you.</p>
                <p>so just enjoy them</p>
            </div>
            <div className="media-container">
                <div className="media-section">
                    <h3>AI Videos</h3>
                    {videos.map((video, index) => (
                        <Card
                            key={index}
                            hoverable
                            className="media-card"
                            cover={<img src={video.path} alt={`AI Video ${index}`} />}
                            onClick={() => setSelectedVideo(video.path)}
                        />
                    ))}
                </div>
            </div>
            <Footer className="footer">
                    <div className="footer-icons">
                        <a href="https://discordapp.com/users/yourid" target="_blank" rel="noopener noreferrer">
                            <FaDiscord className="icon" />
                        </a>
                        <a href="https://united-prompt.tistory.com" target="_blank" rel="noopener noreferrer">
                            <FaBloggerB className="icon" />
                        </a>
                        <a href="mailto:your-email@example.com">
                            <FaEnvelope className="icon" />
                        </a>
                    </div>
                    <div className="footer-text">
                        Made by useSword
                    </div>
                </Footer>
        </div>
    );
}

export default ContentSectionOne;
