import React, { useState,useContext } from 'react';
import RegistrationForm from '../components/auth/Form/RegistrationForm';
import TopMenu from '../menu/topMenu';
const RegistrationPage = () => {



    return (
      <div>
        <TopMenu/>
        <RegistrationForm />
      </div>
    );
  };
  
  export default RegistrationPage;