import { create } from 'zustand'
import { auth } from './FbaseInstance';


const useStore = create((set) => ({
  init: false,
  isLoggedIn: false,
  userObj: null,
  isOwner: false,
  darkMode: false,
  tweet: '',
  tweets: [],
  //메인페이지
  MainPageImages: [
    "https://i.imgur.com/2ZXzZ6t.png",
    // "https://i.imgur.com/lvqnF97.png",
    "https://i.imgur.com/lKdOwPZ.jpg",

],
  RecommandPictures:[
  "https://i.imgur.com/2ZXzZ6t.png",
  "https://i.imgur.com/lvqnF97.png",
  "https://i.imgur.com/lKdOwPZ.jpg",
  "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifntIdEt3UrEwV6_yCknMJQYauNlS9uFjdiAo-GkdrsHlTUQM6R88ADvwO2Y_UIeKOUx8DP4F9r1m0rXCpx_hTWu5055B9QFRTh2X5Gwd0SN71aiQCSOk6PCWi3QoLRABFFhlZ_b6gAPjZAvprRmmdUNP3bKIEEDHVC6P7J_YQMOaYva0_tdyurMWXYJk/s1600/20230624_181719_608501.png",
  "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj_BmZFmXjloROC_J6HjiOxQbehvpwc8ICkNdHXMOKl78Z1O9S9jjL-6_0LHEdfwiocr9zdnbNf7JbSDIGEKHDc7MDP6GSa6qFBSkqvR-mBmFOEsOgQVDwopLjrruwdGvhUH2rEgVXCyyGES1yuevIJJRsBYb99s5koSLJZ3dqQ4Fkg5OofrRAGqoBZBtw/s1600/20230624_181938_305103.png",
  "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEj9-sGqsGM1LCNKxI7T4x7jFA7A0vN59ty_QqllFhWUyZaJJf8Xxsh3-2yFV86OHaBtAM_6ypD3_wDZ4hcRHp4a7I4-cq7MiNNTN4pN8LQ6GNU0h9U81SIo7r3UJyuxOVdfewWX7tidLop7VT5N7x9noxqwr2yaQ7M8vhxgUYeCxBkP-UtLoyMq885o29g/s1600/20230624_182110_694818.png",
],

  toggleDarkMode: () => set(state => ({ darkMode: !state.darkMode })), 
  setInit: (value) => set({ init: value }),
  setIsLoggedIn: (value) => set({ isLoggedIn: value }),
  setUserObj: (value) => set({ userObj: value }),
  setIsOwner: (value)=> set({ isOwner: value }),
  setTweet: (value) => set({ tweet: value }),
  setTweets: (value) => set({ tweets: value }), 
  setMainPageImages: (value) => set({ MainPageImages: value }),
  setRecommandPictures:(value)=>set({ RecommandPictures:value}),
  
  addImageToMainPageImages: (image) => set(state => ({ MainPageImages: [...state.MainPageImages, image] })),
  addImageToRecommandPictures: (image) => set(state => ({ RecommandPictures: [...state.RecommandPictures, image] })),
  removeImageFromRecommandPictures: (image) => set(state => ({ RecommandPictures: state.RecommandPictures.filter(img => img !== image) })),
  removeImageFromMainPageImages: (value) => set(state => ({ MainPageImages: state.MainPageImages.filter(image => image !== value) })),
  authStateChanged: () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        set({ isLoggedIn: true });
        set({ userObj: user });

      } else {
        set({ isLoggedIn: false });
      }
      set({ init: true });
    });
  },
}));

export default useStore;
