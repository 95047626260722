import React from 'react';
import { Tab, Tabs, Card, Button  } from 'react-bootstrap';
import './BotGuidePage.css';
import TopMenu from '../menu/topMenu';
import { motion } from 'framer-motion';

const BotGuidePage = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1, 
            transition: { duration: 0.5 } 
        },
    };
    return (
        <div>
            <TopMenu/>
            <motion.div 
                className="guide-page-wrapper"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                 <div className="guide-page-content">
            <h1 className="background-text" >Bot Guide Page</h1>

            <Tabs defaultActiveKey="promptHelper" id="bot-guide-tabs" className="bot-guide-tabs">
                <Tab eventKey="promptHelper" title="Prompt Helper">
                <motion.div className="guide-page-bot-section">
                        <h2 className="guide-page-bot-title">Prompt Helper</h2>
                        <p className="guide-page-bot-description">"prompt helper"는 "stable diffusion"에서 프롬프트 작성에 도움을 주기위한 봇입니다.<br/>테마에 대해 작성해주면 그 테마에 알맞는 프롬프트들을 chat gpt 3.5가 작성해줍니다.
</p>
                    </motion.div >
                    <motion.div className="guide-page-bot-section">
                        <h2 className="guide-page-bot-title">영상</h2>
                        <div className="guide-page-bot-description">
                            <p>나중에 만들게여 꼭</p>
                        </div>
                    </motion.div>
                    <motion.div className="guide-page-bot-section">
                        <h2 className="guide-page-bot-title">사용법</h2>
                        <p className="guide-page-bot-description">
                        1. 메뉴에 있는 promptHelper를 클릭.<br/><br/>
                        2. 메세지칸에 "theme"를 반드시 작성해주시고 그 이후 원하는 주제를 적어주면 됩니다. <br/>예를 들면 "The theme is a white, beautiful vampire sleeping in a coffin."<br/><br/>
                        3. 좀 오래 기다려야합니다. 그리고 답변이 아래와 같이 옵니다. 그 프롬프트를 사용하시면 됩니다.<br/><br/>Situation: (A white-skinned vampire rests peacefully in an ornate coffin adorned with roses:1.3), <br/>Clothes: (Her long, flowing dress is pure white and adorned with delicate lace:1.2), (Her white stockings are less noticeable among the flowers:1), <br/>Hair: (Smooth black hair contrasts beautifully with her pale skin:1.1), Background and details: (The dimly lit room is spacious and filled with candles:1.2), (Ornamented curtains draped over tall windows:1.1), (The sunlight filtering through the draped curtains creates an ethereal atmosphere:1.2), <br/>Effect: (A peaceful and serene mood is given off by the ethereal lighting and the exquisitely arranged roses in the room:1.3).
                        <br/>이거 stable diffusion 1.5버전은 이 프롬프트 쳣는데 뱀파이어는 안나오고 송곳니가 없는 이쁜 여성 나옵니다. 개같은 것 
                        </p>
                    </motion.div>
                </Tab>



                <Tab eventKey="wildcardHelper" title="Wildcard Helper">
                <motion.div className="guide-page-bot-section">
                        <h2 className="guide-page-bot-title">Wildcard Helper</h2>
                        <p className="guide-page-bot-description">"Wildcard Helper"는 아직 완성되지 않았습니다.</p>
                    </motion.div>
                    <motion.div className="guide-page-bot-section">
                        <h2 className="guide-page-bot-title">영상</h2>
                        <p className="guide-page-bot-description">
                            {/* 작성해 */}
                        </p>
                    </motion.div>
                    <motion.div className="guide-page-bot-section">
                        <h2 className="guide-page-bot-title">사용법</h2>
                        <p className="guide-page-bot-description">
                
                        </p>
                    </motion.div>
                </Tab>

                {/* Add more tabs as needed */}
            </Tabs>
            </div>
        </motion.div>
        </div>
    );
};

export default BotGuidePage;



// "prompt helper" is a bot to help with "stable diffusion". You can write about your theme and it will create prompts for that theme. It is equipped with chat gpt 3.5.

// To use it, just write "theme" in the message box and then write whatever you want.

// For example: "theme is a white, very beautiful vampire in a coffin" and it will respond with "" and so on.