import React, { useEffect } from 'react';

const GoogleLoginButton = () => {
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: '1024171005343-dq4mipn1nkuisdi5rjnf4kv4nljj8cta.apps.googleusercontent.com',
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-btn'),
      {}  // customization attributes
    );
    window.google.accounts.id.prompt(); // Will prompt only on button click
  }, []);

  const handleCredentialResponse = (response) => {
    // handle the ID token in the response here
    console.log(response.credential);
  };

  return <div id="google-signin-btn"></div>;
};

export default GoogleLoginButton;
