// WorkspacePage.jsx
import React, { useState } from 'react';
import { useTopMenu } from '../TopMenuContext';
import TopMenu from '../topMenu';
import './WorkspacePage.scss';

const WorkspacePage = () => {
  const [input, setInput] = useState('');
  const { unlockFeature } = useTopMenu();

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = () => {
    if (input === 'my1stbot') {
      unlockFeature('Chatbot');
      alert('SUCCESS: Chatbot Unlocked.');
    }
    setInput('');
  };

  return (
    <>
      <TopMenu />
      <div className="workspace-page">
        {/* 페이지 내용 */}
        <div className="input-container">
          <input type="text" value={input} onChange={handleInput} />
          <button onClick={handleSubmit}>Enter</button>
        </div>
      </div>
    </>
  );
};

export default WorkspacePage;
